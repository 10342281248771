<template>
  <div class="app-container">

    <div class="right_box">
      <el-button type="primary" @click="handleDownloadVat()" size="small">导出</el-button>
      <el-button size="small" @click="printingNew()" type="success">打印</el-button>
    </div>

    <!-- 印花税纳税申报（报告）表 -->
    <div class="top_title clearfix">
      <h4>印花税纳税申报（报告）表<span v-if="tableName == 'gs_stamp_duty_item_cb'">（次报）</span><span v-else-if="tableName == 'gs_stamp_duty_item_jb'">（季报）</span></h4>
      <div class="center_title clearfix">
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
      </div>
      <div class="bottom_time clearfix">
        <div class="top_input2 clearfix fl">
          <h6>填表日期：</h6>
            <el-date-picker v-model="value1" style="width:30%;display:inline-block" type="datetime" format="yyyy-MM-dd" placeholder="选择日期时间" size="small"></el-date-picker>
        </div>
        <div class="top_input3 clearfix fr">
          <h6>纳税人识别号：</h6>
          <span>{{taxNo}}</span>
        </div>
      </div>
      <div class="bottom_time clearfix">
        <div class="top_input2 clearfix fl">
          <h6>纳税人名称（公章）：</h6>
          <span>{{comName}}</span>
        </div>
        <div class="top_input3 clearfix fr">
          <h6>金额单位：元（列至角分）</h6>
          <span>165165161</span>
        </div>
      </div>
    </div>
    <!-- 顶部标题 end -->

    
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td rowspan="3" class="center weight" style="width:17%">应用凭证名称</td>
          <td class="center weight" rowspan="2" style="width:10%">计税金额或件数</td>
          <td colspan="2" class="center weight" style="width:17%">核定征收</td>
          <td class="center weight" rowspan="2" style="width:8%">适用税率(%)</td>
          <td class="center weight" rowspan="2" style="width:8%">本期应纳税额</td>
          <td class="center weight" rowspan="2" style="width:8%">本期已纳税额</td>
          <td class="center weight" colspan="2" style="width:16%">本期减免税额</td>
          <td class="center weight" rowspan="2" style="width:8%">本期增值税小规模纳税人减征额</td>
          <td class="center weight" rowspan="2" style="width:8%">本期应补（退）税额</td>
        </tr>
        <tr class="top_bg">
          <td class="center weight" style="width:9%">核定计税依据</td>
          <td class="center weight" style="width:8%">核定比例</td>
          <td class="center weight" style="width:8%">减免性质代码</td>
          <td class="center weight" style="width:8%">减免税额</td>
        </tr>
        <tr class="top_bg">
          <td class="center weight">1</td>
          <td class="center weight">2</td>
          <td class="center weight">3</td>
          <td class="center weight">4</td>
          <td class="center weight">5=1*4+2*3*4</td>
          <td class="center weight">6</td>
          <td class="center weight">7</td>
          <td class="center weight">8</td>
          <td class="center weight">9</td>
          <td class="center weight">10=5-6-8-9</td>
        </tr>
      </table>
      <div class="fixed_box_table">
        <table class="content" cellspacing="0">
        
          <tr v-for="(item,index) in info" :key="index">
            <td style="width:17%"><input type="text" v-model="item.name"></td>
            <td style="width:10%"><input type="text" v-model="item.jsje"></td>
            <td style="width:9%"><input type="text" v-model="item.hdyj"></td>
            <td style="width:8%"><input type="text" v-model="item.hdbl"></td>
            <td style="width:8%"><input type="text" v-model="item.sysl"></td>
            <td style="width:8%"><input type="text" v-model="item.bqynse"></td>
            <td style="width:8%"><input type="text" v-model="item.bqyjse"></td>
            <td style="width:8%"><input type="text" v-model="item.jmnum"></td>
            <td style="width:8%"><input type="text" v-model="item.jme"></td>
            <td style="width:8%"><input type="text" v-model="item.small_scale_taxpayer"></td>
            <td style="width:8%"><input type="text" v-model="item.bqybse"></td>
          </tr>

          <tr>
            <td class="center" style="width:17%">合计</td>
            <td style="width:10%"><input type="text" v-model="sum.jsje_sum"></td>
            <td style="width:9%">----</td>
            <td style="width:8%">----</td>
            <td style="width:8%">----</td>
            <td style="width:8%"><input type="text" v-model="sum.bqynse_sum"></td>
            <td style="width:8%"><input type="text" v-model="sum.bqyjse_sum"></td>
            <td style="width:8%">----</td>
            <td style="width:8%"><input type="text" v-model="sum.jme_sum"></td>
            <td style="width:8%">----</td>
            <td style="width:8%"><input type="text" v-model="sum.bqybse_sum"></td>
          </tr>
        
        </table>
      </div>

    <div class="save_box">
      <el-button type="primary" @click="saveValues()" size="small" plain>保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editTax',
  data() {
    return {
    durationOfTaxTime: '',
     activeName: 'first',
     value1: '',
     edit: false,
     info: [],
     sum: {},
     comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  methods: {
    // 公式
    changeList(){

    },
  }
}
</script>

<style lang="scss" scoped>
.border_big .el-input__inner{
  border: none;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.top_title{
  width: 100%;
  margin-bottom: 20px;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    margin: 10px auto;
    text-align: center;
  }
  .center_title{
    // float: left;
    width:30%;
    margin: 0 auto;
    text-align: center;
    h6{
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    input{
    font-size: 14px;
    color: #333;
    line-height: 32px;
    }
  }
  .right_num{
    float: right;
    width:20%;
    text-align: right;
    font-size: 14px;
    color: #333;
  }
  .bottom_time{
    width: 100%;
    margin-top: 10px;
  }
  .top_input2{
    width: 50%;
    h6{
      display: inline-block;
      // width: 70px;
      line-height: 28px;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .top_input3{
    width: 50%;
    text-align: right;
    h6{
      display: inline-block;
      // width: 100px;
      line-height: 32px;
      font-size: 14px;
      font-weight: normal;
    }

  }
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 14px;
  }
}
.app-container{
  height: 100%;
  position: relative;
  padding: 20px;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
</style>